import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './style.scss';

class PopUp extends React.Component {
  static identifier = 'PopUp';

  static propTypes = {
    city: PropTypes.string
  };

  static defaultProps = {
    city: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      temperature: 0,
      icon: 'null'
    };
  }


  componentDidMount() {
    // this.getWeather();
  }

  render() {
    return (
      <div id="popUp">
        <div id="popupcover"></div>
        <div  id="popupcontainer">
          <div id="popUpTitle">DEMO ONLY</div>
          <div id="popUpDesc">This product has limited functionality and needs to be adapted for the target platform.</div>
          <div  id="popUpButton">OK</div>
        </div>

      </div>
    );
  }
}

export default PopUp;
