/* eslint-disable import/prefer-default-export */

export function isPreProduction() {
  return process.env.REACT_APP_BUILD_MODE === 'preproduction';
}

export function isProduction() {
  return process.env.REACT_APP_BUILD_MODE === 'production';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function setCookie(cname, cvalue, exhours) {
  const d = new Date();
  d.setTime(d.getTime() + exhours * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

/*
 * element: html p element with the text
 * spaceAvailable: space the p element should have
 * array: empty array
 * */
export function animateText(element, spaceAvailable, array) {
  if (!element) {
    return;
  }
  const velocity = 100;
  const movement = spaceAvailable - element.scrollWidth;
  if (movement < 0) {
    array[0] = setTimeout(() => {
      element.style.textOverflow = 'unset';
      element.style.marginLeft = `${movement}px`;
      element.style.transition = `margin-left ${Math.abs(movement) / velocity}s linear`;
      array[1] = setTimeout(() => {
        element.style.transition = `margin-left ${Math.abs(movement) / velocity}s linear`;
        element.style.marginLeft = `0px`;
        setTimeout(() => {
          animateText(element, spaceAvailable, array);
        }, (Math.abs(movement) / velocity) * 1000);
      }, (Math.abs(movement) / velocity + 2) * 1000);
    }, 1000);
  }
}
/*
 * element: html p element with the text
 * array: array used in animateText function
 * */
export function inanimateText(element, array) {
  if (!element) {
    return;
  }
  for (let i = 0; i < 2; i++) {
    clearTimeout(array[i]);
    array[i] = undefined;
  }
  element.style.textOverflow = 'ellipsis';
  element.style.marginLeft = '0px';
  element.style.transition = 'none';
}

/* eslint-disable no-undef  */
export function getInstalledApps(callback = () => {}) {}

export function installApp(id, appName, logo, appUrl) {}

export function uninstallApp(id) {}

export function getBrand() {
  try {
    const brand = typeof Hisense_GetBrand === 'function' ? Hisense_GetBrand() : process.env.REACT_APP_BRAND; // to do: function to get proper brand name
    switch (brand) {
      case 'nob':
      case 'noblex':
        return 'nob';
      case 'turk':
        return 'turk';
      case 'AVTEX':
      case 'tekno':
        return 'tekno';
      default:
        return 'default';
    }
  } catch (e) {
    return 'default';
  }
}
